<template>
  <div id="search" class="zweigi">
      <h2>Suche</h2>
      <v-text-field hide-details :prepend-icon="magnify" single-line v-model.lazy="input"></v-text-field>
      <v-divider></v-divider>
      <v-btn v-on:click="refr" block outlined>Suche Starten</v-btn>
    <v-divider></v-divider>
     <div v-for="(post,index) in result" :key="post.slug + '_' + index">
      <v-card :to="'/' + post.slug">
        <v-card-title class="title pb-4">
            <h4 class="tit">{{ post.name }}</h4>
        </v-card-title>
      </v-card>
      <v-divider></v-divider>
      </div>
  </div>
</template>

<script>
  import { db } from '../db'
  const lernzettelp = db.collection('zweig');
  import Fuse from 'fuse.js'
  import { mdiMagnify } from '@mdi/js'

  var options = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      "name"
    ]
  };

  export default {
    name: 'search',
    data() {
      return {
        posts: {},
        input: "",
        result: [],
        magnify: mdiMagnify
      }
    },
    methods: {
      refr: function(){
        var fuse = new Fuse(this.posts.flatmap, options);
        this.result = fuse.search(this.input)
      }
    },
    mounted(){
      lernzettelp.doc("flatmap").get().then(snapshot => this.posts = snapshot.data()).then(() => this.$Progress.finish());
    }
  }
</script>

<style scoped>
.title{
    padding-bottom: 0px;
}
.text{
    padding-top: 0px;
}
.tit{
    margin: 0px 0px 0px;
}
</style>