<template>
  <div id="lernzettel" class="zweigi" v-if="lernzettel">
    <div>
      <div class="main" v-html="lernzettel.content"></div>
    </div>
    <div v-if="lernzettel.kapitel !== undefined && lernzettel.kapitel.length !== 0">
      <v-divider></v-divider>
      <h2>Inhalt</h2>
      <v-list>
      <div v-for="Fach in lernzettel.kapitel" :key="Fach.name">
        <div v-if="Fach.kapitel !== undefined">
          <v-list-group :value="false">
              <template v-slot:activator>
                <v-list-item class="pa-0">
                  <v-list-item-title class="body-2">{{Fach.name}}</v-list-item-title>
                </v-list-item>
              </template>
              <div v-for="Thema in Fach.kapitel" :key="Thema.name">
                <div v-if="Thema.kapitel !== undefined">
                  <v-list-group no-action sub-group :value="false">
                    <template v-slot:activator>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="body-2">{{Thema.name}}</v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list-item v-for="Lernzettel in Thema.kapitel" :key="Lernzettel.slug" :to="'/' + Lernzettel.slug">
                      <v-list-item-content class="body-2">{{Lernzettel.name}}</v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </div>
                <div v-else>
                  <v-list-item :key="Thema.slug" :to="'/' + Thema.slug" class="ml-6">
                      <v-list-item-content class="body-2">{{Thema.name}}</v-list-item-content>
                  </v-list-item>
                </div>
              </div>
          </v-list-group>
        </div>
        <div v-else>
          <v-list-item :key="Fach.slug" :to="'/' + Fach.slug">
            <v-list-item-content class="body-2">{{Fach.name}}</v-list-item-content>
          </v-list-item>
        </div>
      </div>
      </v-list>
    </div>
    <v-divider></v-divider>
    <div class="columns d-print-none">
      <div class="column" v-if="lernzettel.lasts">
        <p class="body-2">vorheriges Kapitel</p>
        <v-card :to="'/' + lernzettel.lasts" color="#2ecc71">
          <p class="ma-0 subtitle-1 pa-3">{{lernzettel.lastn}}</p>
        </v-card>
      </div>
      <div class="column" v-if="lernzettel.nexts">
        <p class="body-2">nachfolgendes Kapitel</p>
        <v-card :to="'/' + lernzettel.nexts" color="#2ecc71">
          <p class="ma-0 subtitle-1 pa-3">{{lernzettel.nextn}}</p>
        </v-card>
      </div>
    </div>
    <div v-if="lernzettel.hierarchie">
      <v-divider></v-divider>
      <p class="body-2">Einordnung</p>
      <div class="columns d-print-none">
        <div class="column" v-for="hier in lernzettel.hierarchie" :key="hier.slug">
          <v-card :to="'/' + hier.slug" color="#e67e22">
            <p class="ma-0 subtitle-1 pa-3">{{hier.name}}</p>
          </v-card>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <v-card href="https://lernzettel.org" target="_blank">
        <v-card-title class="subtitle-1 ma-0 pe">
              lernzettel.org
          </v-card-title>
    </v-card>
    <v-divider></v-divider>
    <v-card href="https://null-papier.de/shop/stefan-zweig-gesammelte-werke/" target="_blank">
      <v-card-text>
        <p class="ma-0">
          Die hier vorzufindene Sammlung der gemeinfreien Werke Stefan Zweigs ist aus der Ausgabe des Null Papier Verlages übernommen. Zu dieser Ausgabe gelangen Sie durch einen Klick auf diesen Eintrag.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.main >>> h1{
  color: #1f3864;
}
.main >>> h2{
  color: #2f5496;
}
h2 {
  color: #2f5496;
}
.main >>> h4{
  color: #8eaadb;
}
.main >>> ol{
  margin-block-start: 0px;
}
.main >>> table{
  overflow-x: auto;
  overflow-y: hidden;
}
.main >>> table{
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  display: block;
}
.main >>> .MathJax_Display{
      overflow-x: auto;
      overflow-y: hidden;
}
.main >>> .MathJax_Preview{
      display: none;
}
.main >>> p {
  overflow-x: auto;
}
.pe{
  padding-bottom: 16px;
}
</style>

<script>
  import { db } from '../db'
  const lernzettelp = db.collection('zweig');
  export default {
    name: 'lernzettel',
    data() {
      return {
        lernzettel: {}
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
          lernzettelp.doc(this.$route.params.lernzettel).get().then(snapshot => this.lernzettel = snapshot.data()).then(() => this.$Progress.finish());
        }
      }
    },
    mounted: function(){
      if(this.$route.params.lernzettel !== ''){
        lernzettelp.doc(this.$route.params.lernzettel).get().then(snapshot => this.lernzettel = snapshot.data()).then(() => this.$Progress.finish());
      }
    },
    metaInfo(){
      if(this.lernzettel){
      return {
        title: "Stefan Zweig - " + this.lernzettel.name
      }
      }
    }
  }
</script>