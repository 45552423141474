<template>
  <div id="home" class="zweigi">
    <div id="head">
      <div id="spacer">
      <h1>Stefan Zweig</h1>
      <h4>Gesammelte Werke</h4>
      </div>
    </div>
    <v-divider></v-divider>
    <v-card href="https://null-papier.de/shop/stefan-zweig-gesammelte-werke/" target="_blank">
      <v-card-text class="body-2">
        <p class="blackx">
          Die hier vorzufindene Sammlung der gemeinfreien Werke Stefan Zweigs ist aus der Ausgabe des Null Papier Verlages übernommen. Zu dieser Ausgabe gelangen Sie durch einen Klick auf diesen Eintrag.
        </p>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card>
      <v-card-text class="body-2">
        <p class="blackx">
        Denn je tiefer wir uns in ihn versenken, desto tiefer fühlen wir uns selbst.<br />
        Stefan Zweig über Fjodor Dostojewski<br />
        <br />
        Ich wünsche Ihnen viel Spaß bei der Wanderung durch das wortgewaltige Gebirge der Werke Stefan Zweigs - 
        auf dass Sie sich im Versenken in ihn selbst erkunden.<br />
        <br />
        Mit freundlichem Gruß<br />
        <br />
        Leonard Storcks<br />
        Kiel, den 11.12.19
        </p>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <h3>Empfehlungen</h3>
    <div v-for="post in posts" :key="post.slug">
      <v-card :to="'/' + post.slug">
          <v-card-title class="title">
              <h5 class="tit">{{ post.title }}</h5>
          </v-card-title>
          <v-card-text class="text">
              <p class="body-1">{{ post.summary }}</p>
          </v-card-text>
      </v-card>
      <v-divider></v-divider>
    </div>
    <v-card href="https://lernzettel.org" color="#e67e22" target="_blank">
        <v-card-title class="title">
              <h5 class="tit">lernzettel.org</h5>
          </v-card-title>
          <v-card-text class="text">
              <p class="body-1">Plattform für Lernzettel, Präsentationen und Hausarbeiten.</p>
          </v-card-text>
      </v-card>
      <v-divider></v-divider>
  </div>
</template>

<style scoped>
#head{
    background-color: #2c3e50;
    text-align: center;
}
#spacer > h1, #spacer > h4{
    color: #ecf0f1;
}
#head{
    border-radius: 5px;
}
#spacer{
    padding: 20px;
}
a {
  text-decoration: none; /* no underline */
}
.title{
    padding-bottom: 0px;
}
.text{
    padding-top: 0px;
}
.tit{
    margin: 0px 0px 0px;
}
.blackx{
  color: black;
}
</style>

<script>
  export default {
    name: 'home',
    data: () => ({
      posts: [
        {slug: "drei-dichter-ihres-lebens-casanova-stendhal-tolstoi-tolstoi", title: "Tolstoi", summary: "Zweig über Tolstoi"},
        {slug: "drei-dichter-ihres-lebens-casanova-stendhal-tolstoi-casanova", title: "Casanova", summary: "Zweig über Casanova"},
        {slug: "die-fruhen-kranze-gedichte-sinkender-himmel", title: "Sinkender Himmel", summary: "Gedicht"}
      ]
    }),
    mounted(){
      this.$Progress.finish()
    },
    metaInfo: {
      meta: [
          {
            name: 'description',
            content: 'Gesammelte Werke Stefan Zweigs'
          }
        ]
    }
  }
</script>