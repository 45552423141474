import Vue from 'vue'
import Router from 'vue-router'
import lernzettel from './components/lernzettel.vue'
import search from './components/search.vue'
import home from './components/home.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/:lernzettel',
    name: 'lernzettel',
    component: lernzettel
  },
  {
    path: '/funktionen/suche',
    name: 'search',
    component: search
  },
  { path: '*', component: () => import ('@/components/notfound.vue') }
]

const router = new Router({mode: 'history', routes, scrollBehavior (to, from, savedPosition) {
  return { x: 0, y: 0 }
}})

export default router
