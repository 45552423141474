import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyAPCfX8qmDMM9c2MPHq-9sF7YBdFnnv7qs",
    authDomain: "lernzettel-dev.firebaseapp.com",
    databaseURL: "https://lernzettel-dev.firebaseio.com",
    projectId: "lernzettel-dev",
    storageBucket: "lernzettel-dev.appspot.com",
    messagingSenderId: "1047499460498",
    appId: "1:1047499460498:web:8174b9548dea21c402fb4b"
  };

// Get a Firestore instance
export const db = firebase
  .initializeApp(firebaseConfig)
  .firestore()